import { Card, CardMedia, Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Header from '../../components/Header'
import InternalLink from '../../components/InternalLink'
import Footer from '../../components/Footer'
import { PRICING } from '../../navigation/CONSTANTS'

function HomeContainer() {
  
  return (
    <>
      <Header headline="Home"></Header>
      <Grid container direction={"column"} spacing={3}>
        <Grid item sx={{ backgroundColor: "#f9f9f9" }}>
          <Grid item container direction={{ xs: "row", md: "row-reverse" }} justifyContent={{ xs: "center", md: "space-around" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={4}>
            <Grid item xs={10} md={5} lg={4}>
              <Card elevation={0} sx={{ borderRadius: 4 }}>
                <CardMedia image={"https://images.pexels.com/photos/16140853/pexels-photo-16140853.jpeg"} sx={{ height: {xs: "200px" ,sm: "250px",md: "300px"}, filter: "brightness(120%)" }}></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={10} md={5} lg={4}>
              <Typography variant="h5" textAlign={"center"} sx={{ mb: 1, typography:{xs:"h6", md:"h5"} }}>Verein</Typography>
              <Divider></Divider>
              <Typography color="text.secondary" sx={{ textAlign: "center", pt: "20px", pb: "20px", typography: { xs: "body1", md: "h6" } }}>
                Der Braunschweiger HTC zählt mit rund 300 aktiven Mitgliedern zu den drei größten Vereinen Braunschweigs. Wir bieten - mit modernen & digitalen Systemen, einer erstklassigen Anlage sowie einer harmonischen Gemeinschaft - Spielspaß für Anfänger & Leistungsspieler! 
                </Typography>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <InternalLink linkName="Konditionen" route={PRICING}></InternalLink>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{}}>
          <Grid container direction="row" justifyContent={{ xs: "center", md: "space-around" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={4}>
            <Grid item xs={10} md={5} lg={4}>
              <Card elevation={0} sx={{ borderRadius: 4 }}>
                <CardMedia image={"https://media.htc-braunschweig.de/Anlg.jpg"} sx={{ height: {xs: "200px" ,sm: "250px",md: "300px"}, filter: "brightness(130%)" }}></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={10} md={5} lg={4}>
              <Typography variant="h5" textAlign={"center"} sx={{ mb: 1,typography:{xs:"h6", md:"h5"} }}>Anlage</Typography>
              <Divider></Divider>
              <Typography color="text.secondary" sx={{ textAlign: "center", pt: "20px", pb: "20px", typography: { xs: "body1", md: "h6" } }}>
                Wir verfügen über fünf Top-Sandplätze, eine sonnige Terrasse mit Blick auf alle Plätze, sowie ein gastliches Clubhaus. Das Ganze wird eingerahmt von einer schönen Grünanlage.
              </Typography>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                {/* <InternalLink linkName="Mehr erfahren" route={"/"}></InternalLink> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>


        <Grid item sx={{ backgroundColor: "#f9f9f9" }}>
          <Grid item container direction={{ xs: "row", md: "row-reverse" }} justifyContent={{ xs: "center", md: "space-around" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={4}>
            <Grid item xs={10} md={5} lg={4}>
              <Card elevation={0} sx={{ borderRadius: 4 }}>
                <CardMedia image={"https://images.pexels.com/photos/2973104/pexels-photo-2973104.jpeg"} sx={{ height: {xs: "200px" ,sm: "250px",md: "300px"}, filter: "brightness(120%)" }}></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={10} md={5} lg={4}>
              <Typography variant="h5" textAlign={"center"} sx={{ mb: 1,typography:{xs:"h6", md:"h5"} }}>Ligabetrieb</Typography>
              <Divider></Divider>
              <Typography color="text.secondary" sx={{ textAlign: "center", pt: "20px", pb: "20px", typography: { xs: "body1", md: "h6" } }}>Beim HTC hat neben dem Spaß auch der Wettkampf einen hohen Stellenwert. Durch die tolle Jugendarbeit und den vielen aktiven Spielern im Herren- bzw. Damenbereich ist der HTC in sämtlichen Regions-, Bezirks- und Verbandsligen vertreten.</Typography>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <InternalLink linkName="NuLiga Club Portrait" external={true} route={"https://tnb.liga.nu/cgi-bin/WebObjects/nuLigaTENDE.woa/wa/clubInfoDisplay?federation=TNB&region=DE.NO.TNB&targetFed=TNB&club=16171&tab=4"}></InternalLink>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item >
          <Grid item container direction="row" justifyContent={{ xs: "center", md: "space-around" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={4}>
            <Grid item xs={10} md={5} lg={4}>
              <Card elevation={0} sx={{ borderRadius: 4 }}>
                <CardMedia image={"https://images.pexels.com/photos/5739118/pexels-photo-5739118.jpeg"} sx={{ height: {xs: "200px" ,sm: "250px",md: "300px"}, filter: "brightness(120%)" }}></CardMedia>
              </Card>
            </Grid>
            <Grid item xs={10} md={5} lg={4}>
              <Typography variant="h5" color="text.primary" textAlign={"center"} sx={{ mb: 1,typography:{xs:"h6", md:"h5"} }}>Training</Typography>
              <Divider></Divider>
              <Typography color="text.secondary" sx={{ textAlign: "center", pt: "20px", pb: "20px", typography: { xs: "body1", md: "h6" } }}>
                Der HTC bietet Training für alle Leistungsklassen von Anfänger bis ambitionierte Wettkämpfer. Druch die Kooperation mit der Tennisschule Marcel Schelesnow trainieren täglich zwei Trainer Vereinsmitglieder sowie Gäste.
              </Typography>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                {/* <InternalLink linkName="Mehr erfahren" route={"/"}></InternalLink> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ backgroundColor: "#f9f9f9" }}>
          <Grid item container direction={{ xs: "row", md: "row-reverse" }} justifyContent={{ xs: "center", md: "space-around" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={4}>
            <Grid item xs={10} md={5} lg={4}>
              <Card elevation={0} sx={{ borderRadius: 4 }}>
                {/* <CardMedia image={"https://media.myclubportal.de/Devices_croped.png"} sx={{ width: "100%", filter: "brightness(120%)" }}></CardMedia> */}
                <Box component={"img"} src={"https://media.myclubportal.de/Devices_croped.png"} sx={{ width: "90%", borderRadius: 2 }}></Box>
              </Card>
            </Grid>
            <Grid item xs={10} md={5} lg={4}>
              <Typography variant="h5" textAlign={"center"} sx={{ mb: 1,typography:{xs:"h6", md:"h5"} }}>Digitales Club Portal</Typography>
              <Divider></Divider>
              <Typography color="text.secondary" sx={{ textAlign: "center", pt: "20px", pb: "20px", typography: { xs: "body1", md: "h6" } }}>Der HTC nutzt mit großer Zufriedenheit das digitale Vereinsportal von MyClubPortal. Das Portal ermöglicht das Buchen von Plätzen sowie das Management von Mannschaften, Punktspielen, Trainings und vielem mehr.</Typography>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <InternalLink linkName="MyClubPortal Homepage" external={true} route={"https://myclubportal.de"}></InternalLink>
                <InternalLink linkName="HTC Club Portal" external={true} route={"https://portal.htc-braunschweig.de"}></InternalLink>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer/>
    </>
  )
}

export default HomeContainer