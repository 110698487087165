import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, CardMedia, Chip, Collapse, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { sanitize } from 'dompurify';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';


function Post(props) {

    const { title, src, author, content, post } = props;
    const [expandContent, setExpandContent] = useState(true);
 
    const [openImageDialog, setOpenImageDialog] = useState(false);


    return (
        <Card elevation={4} sx={{ border: 0,borderRadius: 2,ml:1 ,mr:1}}>
           
            {/* display image */}
            {src &&
                <CardMedia component="img" src={src} /*sx={{height: 200, objectFit: 'fit'}}*/></CardMedia>
            }
            <CardHeader title={title} subheader={"Datum: " + format(new Date(post.reg_date),"d. MMMM yyyy",{ locale: de }) } subheaderTypographyProps={{ variant: "body2" }}></CardHeader>
            <CardContent>
                {/* Display first two lines, when collapses display all */}
                {/* <Collapse in={!expandContent} transition="auto" unmountOnExit>
                    <Typography dangerouslySetInnerHTML={{ __html: sanitize(content) }}></Typography>
                </Collapse> */}
                <Typography dangerouslySetInnerHTML={{ __html: sanitize(content) }} sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: expandContent ? 3 : 1000,
                    WebkitBoxOrient: 'vertical',
                }}></Typography>
                <Stack>
                    <Button endIcon={expandContent ? <ExpandMore /> : <ExpandLess />} onClick={() => { setExpandContent(!expandContent) }} sx={{textTransform: "none"}}>{expandContent ? "Beitrag lesen" : "Beitrag einklappen"}</Button>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Chip variant="outlined" size="small" color="primary" label={"#" + post.category}></Chip>
                </Stack>
            </CardContent>
            <Divider></Divider>
        </Card>
    )
    }



export default Post