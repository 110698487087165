import React from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';

const FileDownloader = ({ url, fileName }) => {
  const handleDownload = () => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => saveAs(blob, fileName))
      .catch(error => console.error('Error downloading file:', error));
  };

  return (
    <div style={{ margin: "auto", marginTop: "10px" }}>
      <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleDownload}>
        Download
      </Button>
    </div>
  );

};

export default FileDownloader;
