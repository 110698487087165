import { Typography, Grid, Divider } from '@mui/material'
import React from 'react'
import ContactCard from '../../components/ContactCard'
import Header from '../../components/Header'
import contacts from '../../mocks/contacts.json'
import './ProfileCards.css'
import Footer from '../../components/Footer'

function ContactsContainer() {
  return (
    <>
      <Header headline="Ansprechpartner"></Header>
      <Grid container direction={"column"} spacing={3}>
        <Grid item xs={10} md={5} lg={4}>
          
        <Typography sx={{ color: "text.primary", textAlign: "center", typography: { xs: "h4", md: "h4" }, margin: "10px", display:{xs: "none", md: "block"}}}> Ansprechpartner </Typography>
          <Divider sx={{width: "90%", margin: "auto"}}></Divider>
          <Typography color="text.secondary" sx={{ textAlign: "center", pt: "20px", pb: "5px", typography: { xs: "body1", md: "h6" } }}>
            Hier finden Sie Informationen sowie Kontaktdaten zu den Ansprechpartnern des Vereins
          </Typography>
          <Typography color="text.secondary" sx={{ textAlign: "center", pt: "5px", pb: "20px", typography: { xs: "body1", md: "h6" } }}>
            Für allgemeine Anfragen nutzen Sie bitte folgende Email Adresse: info@htc-braunschweig.de
          </Typography>
        </Grid>
      </Grid>
      <div className="profile-cards-container">
      {contacts.map((profile) => (
        <div className="profile-card">
          <ContactCard {...profile} />
        </div>
      ))}
      </div>
      <Footer></Footer>
    </>
  )
}

export default ContactsContainer