import React from 'react'
import Header from '../../components/Header'
import { Container } from '@mui/material'
import events from '../../mocks/events.json'
import Timeline from '@mui/lab/Timeline';
import EventTimelineItem from '../../components/EventTimelineItem'


function EventsContainer() {

    return (
        <>
            <Header headline="Termine"></Header>
            <Container maxWidth="xl" sx={{px:1}}>
                <Timeline>
                    {events.map((event, i) => (
                        <EventTimelineItem event={event} hideConnector={i==events.length-1} key={i}/>
                    ))
                    }
                </Timeline>
            </Container>
        </>
    )
}

export default EventsContainer