import { Grid } from '@mui/material'
import { Container } from '@mui/system';
import React from 'react'
import Header from '../../components/Header'
import SubscritpionCard from '../../components/SubscritpionCard';
import Subscriptions from "../../mocks/subscriptions.json"
import Footer from '../../components/Footer';

function PricingContainer() {

    return (
        <>
            <Header headline="Mitgliedschaft"></Header>
            <Container maxWidth={"xl"}>
                <Grid container direction={"row"} justifyContent={"space-around"} spacing={3} mt={5} p={1}>
                    {Subscriptions.map((subscription, i) => (
                        <Grid item xs={11} sm={6} md={4} lg={3} key={i}>
                            <SubscritpionCard data={subscription}></SubscritpionCard>
                        </Grid>
                    ))
                    }
                </Grid>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default PricingContainer