import { Avatar, Card, CardContent, Divider, Typography, Stack } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import React from 'react'
function ContactCard({ image, fullname, role, description, email, phone }) {

  return (
    <>
      <Card variant="outlined" sx={{ maxWidth: 400, margin: "auto", borderWidth: "2px" }}>
        <CardContent>
          <Avatar sx={{ height: 200, width: 200, margin: "auto" }}
            src={image} />
          <Typography gutterBottom variant="h5" component="div">
            {fullname}
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            {role}
          </Typography>
          <Divider></Divider>
          {/* <Typography variant="body2" color="textSecondary" margin="10px">
              {description}
            </Typography> */}
          <Divider></Divider>
          {email &&
            <Stack direction="row" alignItems="center" gap={3} marginTop="10px" marginLeft="10px">
              <EmailIcon color="primary" />
              <Typography variant="body2" color="textSecondary">{email}</Typography>
            </Stack>
          }

          {phone &&
            <Stack direction="row" alignItems="center" gap={3} marginTop="10px" marginLeft="10px">
              <PhoneIphoneIcon color="primary" />
              <Typography variant="body2" color="textSecondary">{phone}</Typography>
            </Stack>
          }

        </CardContent>
      </Card>
    </>
  )
}

export default ContactCard