
import { AppBar, Grid, Link, Toolbar, Typography } from '@mui/material';
import React from 'react'
import InternalLink from './InternalLink';
import { IMPRINT } from '../navigation/CONSTANTS';


function Footer() {
    return (
        <>
            <AppBar sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar variant="dense" sx={{ backgroundColor: "white" }}>
                    <Grid container justifyContent="space-between" alignItems={"center"}>
                        <Grid item >
                            <Link href={"mailto:info@htc-braunschweig.de"}><Typography variant="body2">@Kontakt</Typography></Link>
                        </Grid>

                        <Grid item>
                            <InternalLink route={IMPRINT} linkName={"Impressum/Credits/Datenschutz"}></InternalLink>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar></Toolbar>
        </>
    )
}


export default Footer;