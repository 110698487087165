import { Avatar, Card, CardContent, Divider, Typography, Stack, Grid, Link } from '@mui/material'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BadgeIcon from '@mui/icons-material/Badge';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import LinkIcon from '@mui/icons-material/Link';
import React from 'react'
function TeamCard({ image, leader, phone, team, time, league, nuliga }) {

    return (
        <>
            <Grid container direction={"column"} spacing={1}>
                <Grid item container direction={{ xs: "row", md: "row" }} justifyContent={{ xs: "center", md: "space-around" }} alignItems="center" sx={{ pb: 5, pt: 5, marginTop: 5 }} spacing={0}>
                    <Grid item xs="auto">
                        <Card elevation={0} sx={{ borderRadius: 2 }}>
                            <CardContent>
                                <Avatar sx={{ height: 70, width: 70, margin: "auto" }} src={image} />
                                <Typography textAlign={"center"} gutterBottom variant="body2" component="div" color="text.secondary">
                                    {leader}
                                </Typography>
                                <Divider></Divider>
                                {phone &&
                                    <Stack direction="row" alignItems="center" gap={1} marginTop="10px" marginLeft="10px">
                                        <PhoneIphoneIcon color="primary" />
                                        <Typography variant="body2" color="textSecondary">{phone}</Typography>
                                    </Stack>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={10} md={3} lg={1}>
                        <Stack direction="row" alignItems="center" gap={1} marginTop="10px" marginLeft="10px">
                            <BadgeIcon color="primary" />
                            <Typography textAlign={"center"} fontWeight="bold" variant="body1" color="text.secondary">
                                {team}
                            </Typography>
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={10} md={3} lg={1}>
                        <Stack direction="row" alignItems="center" gap={1} marginTop="10px" marginLeft="10px">
                            <AccessTimeIcon color="primary" />
                            <Typography textAlign={"center"}  variant="body2" color="text.secondary">
                                {time}
                            </Typography>
                        </Stack>
                    </Grid> */}
                    <Grid item xs={10} md={3} lg={1}>
                        <Stack direction="row" alignItems="center" gap={1} marginTop="10px" marginLeft="10px">
                            <SportsTennisIcon color="primary" />
                            <Typography textAlign={"center"}  variant="body2" color="text.secondary">
                                {league}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={10} md={3} lg={1}>
                        <Stack direction="row" alignItems="center" gap={1} marginTop="10px" marginLeft="10px">
                            <LinkIcon color="primary" />
                            <Typography textAlign={"center"}  variant="body2" color="text.secondary">
                                <Link href={nuliga} target="_blank">nuLiga</Link>
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ width: "90%", margin: "auto" }}></Divider>
        </>
    )
}

export default TeamCard