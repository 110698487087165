import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';

// Set the worker path for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfFirstPageImage = ({ pdfUrl }) => {
  const [pageImage, setPageImage] = useState(null);

  useEffect(() => {
    const fetchPdfPage = async () => {
      try {
        const pdf = await pdfjs.getDocument(pdfUrl).promise;
        const page = await pdf.getPage(1); // Get the first page (page number starts from 1)

        const scale = 0.35; // Adjust the scale to control the image size (1 = original size)
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext,
          viewport,
        };

        await page.render(renderContext).promise;
        setPageImage(canvas.toDataURL()); // Set the base64 image data as the state
      } catch (error) {
        console.error('Error while rendering the PDF page', error);
      }
    };

    fetchPdfPage();
  }, [pdfUrl]);

  return (
    <div>
      {pageImage && <img src={pageImage} alt="PDF First Page" style={{ height: "300px", width: "auto", marginLeft: "50px"}} />}
    </div>
  );
};

export default PdfFirstPageImage;
