import { ContactPage, Mail, Person } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'

import Footer from '../../components/Footer'
import SimpleListItem from '../../components/SimpleListItem'
import Header from '../../components/Header'
import InternalLink from '../../components/InternalLink'

function ImprintContainer() {

    return (
        <>
            <Header headline="Impressum" />
            <Container sx={{ maxWidth: "md", mt: 2 }}>
                <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                    <Grid container item justifyContent={"center"} spacing={2}>
                        <Grid item xs={12} md={10}>
                            <Card elevation={0} variant="outlined" sx={{ borderWidth: "2px" }}>
                                <CardHeader title={"Impressum"} sx={{ textAlign: "center" }} />
                                <CardContent>
                                    <Grid container rowSpacing={2}>
                                        <Grid item container direction="column" alignItems={"center"}>
                                            <Grid item><Typography variant="h6">Heidberger Tennis Club e.V.</Typography></Grid>
                                            <Grid item> <Typography variant="body2" color={"text.secondary"}>An der Bezirkssportanlage Heidberg</Typography></Grid>
                                            <Grid item> <Typography variant="body2" color={"text.secondary"}>Salzdahlumer Straße 130, 38126 Braunschweig</Typography></Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider variant="middle"></Divider>
                                        </Grid>
                                        <Grid item container direction="column" alignItems={"center"} justifyContent="center" xs={12}>
                                            <Grid item>
                                                <Stack direction="column">
                                                    <SimpleListItem icon={<Mail color="primary"></Mail>} object="info@htc-braunschweig.de"></SimpleListItem>
                                                    <SimpleListItem icon={<ContactPage color="primary" />} object="www.htc-braunschweig.de"></SimpleListItem>
                                                    <SimpleListItem icon={<Person color="primary" />} object="Detlef Koch (1.Vorsitzender)"></SimpleListItem>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider variant="middle"></Divider>
                                        </Grid>
                                        <Grid item container direction="column" alignItems={"center"} xs={12}>
                                            <Typography variant="body2" color={"text.secondary"} textAlign="center">Der HTC ist im Vereinsregister der Stadt Braunschweig mit der Nr. 3058 eingetragen.</Typography>
                                        </Grid>
                                        <Grid item container direction="column" alignItems={"center"}>
                                            <Typography textAlign="center" color={"text.secondary"} sx={{ fontSize: "10px" }}>Copyright Maximilan Steinborn, Jan-Paul Brückmann 2023</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                            </Card>
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <Card elevation={0} variant="outlined" sx={{ borderWidth: "2px" }}>
                                <CardHeader title={"Credits"} sx={{ textAlign: "center" }} />
                                <CardContent>
                                    <Grid container rowSpacing={2}>
                                        <Grid item container direction="column" alignItems={"left"}>
                                            <Grid item><Typography variant="h6">Bildmaterial</Typography></Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider variant="middle"></Divider>
                                        </Grid>
                                        <Grid item container direction="column" alignItems={"start"} justifyContent="flex-start" xs={12}>
                                            <Grid item>
                                                <InternalLink linkName="Pexels | Tik Rudari" external={true} route={"https://www.pexels.com/de-de/foto/luftaufnahme-von-mir-und-meinem-freund-beim-tennisspielen-es-war-ein-unentschieden-lol-16140853/"} />
                                            </Grid>
                                            <Grid item>
                                                <InternalLink linkName="Pexels | Harrison Haines" external={true} route={"https://www.pexels.com/de-de/foto/leute-die-auf-blauem-und-grunem-tennisplatz-stehen-2973104/"} />
                                            </Grid>
                                            <Grid item>
                                                <InternalLink linkName="Pexels | cottonbro studio" external={true} route={"https://www.pexels.com/de-de/foto/hand-tennis-festhalten-spieler-5739118/"} />
                                            </Grid>
                                            <Grid item>
                                                <InternalLink linkName="Freepik | coolvector" external={true} route={"https://de.freepik.com/vektoren-kostenlos/realistischer-weisser-und-goldener-geometrischer-hintergrund_18262593.htm#query=realistic-white-golden-geometric-background&position=0&from_view=search&track=sph&uuid=e1d7b724-c1f1-49ee-9a4c-47cd8ce37e4b"} />
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="body2" sx={{ml: 1}}>Susanne Hübner | Tennisanlage Heidberger TC (Draufsicht)</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                            </Card>
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <Card elevation={0} variant="outlined" sx={{ borderWidth: "2px" }}>
                                <CardHeader title={"Datenschutz"} sx={{ textAlign: "center" }} />
                                <CardContent>
                                    <Grid container rowSpacing={2}>
                                        <Grid item container direction="column" alignItems={"left"}>
                                            <Grid item><Typography variant="h6">Datenschutzerklärung Heidberger Tennis-Club</Typography></Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider variant="middle"></Divider>
                                        </Grid>
                                        <Grid item container direction="column" alignItems={"start"} justifyContent="flex-start" xs={12}>
                                            <Grid item>
                                                <Typography>
                                                    <p ><span >gem&auml;&szlig; Artikel 13 und 14 DSGVO</span><br /><br /><span ><strong>1. Namen und Kontaktdaten des Verantwortlichen sowie gegebenenfalls seiner Vertreter:</strong></span><br /><br /><span >Heidberger Tennis-Club (HTC) e.V.</span><br /><span >Salzdahlumer Strasse 130</span><br /><span >38126 Braunschweig</span><br /><span >gesetzlich vertreten durch den&nbsp;<a  href="https://htc-braunschweig.de/contacts">Vorstand&nbsp;</a>nach &sect; 26 BGB</span></p>
                                                    <p><span >1. Vorsitzender: Detlef Koch</span><br /><span >2. Vorsitzender</span></p>
                                                    <p ><span ><strong>2. Zwecke, f&uuml;r die personenbezogenen Daten verarbeitet werden:</strong></span></p>
                                                    <ul>
                                                        <li ><span >Die personenbezogenen Daten werden f&uuml;r die Durchf&uuml;hrung des Mitgliedschaftsverh&auml;ltnisses verarbeitet (z.B. Einladung zu Versammlungen, Beitragseinzug, Organisation des Sportbetriebes).</span></li>
                                                        <li ><span >Ferner werden personenbezogene Daten zur Teilnahme am Wettkampf-, Turnier und Spielbetrieb der Landesfachverb&auml;nde an diese weitergeleitet.</span></li>
                                                        <li ><span >Dar&uuml;ber hinaus werden personenbezogene Daten im Zusammenhang mit sportlichen Ereignissen einschlie&szlig;lich der Berichterstattung hier&uuml;ber auf der Internetseite des Vereins, in Auftritten des Vereins in Sozialen Medien sowie auf Seiten der Fachverb&auml;nde ver&ouml;ffentlicht und an lokale, regionale und &uuml;berregionale Printmedien &uuml;bermittelt.</span></li>
                                                        <li >&nbsp;</li>
                                                    </ul>
                                                    <p><strong><span >3. Rechtsgrundlagen, auf Grund derer die Verarbeitung erfolgt:</span></strong></p>
                                                    <ul>
                                                        <li ><span >Die Verarbeitung der personenbezogenen Daten erfolgt in der Regel aufgrund der Erforderlichkeit zur Erf&uuml;llung eines Vertrages gem&auml;&szlig; Artikel 6 Abs. 1 lit. b) DSGVO. Bei den Vertragsverh&auml;ltnissen handelt es sich in erster Linie um das Mitgliedschaftsverh&auml;ltnis im Verein und um die Teilnahme am Spielbetrieb der Fachverb&auml;nde.</span></li>
                                                        <li ><span >Werden personenbezogene Daten erhoben, ohne dass die Verarbeitung zur Erf&uuml;llung des Vertrages erforderlich ist, erfolgt die Verarbeitung aufgrund einer Einwilligung nach Artikel 6 Abs. 1 lit. a) i.V.m. Artikel 7 DSGVO.</span></li>
                                                        <li ><span >Die Ver&ouml;ffentlichung personenbezogener Daten im Internet oder in lokalen, regionalen oder &uuml;berregionalen Printmedien erfolgt zur Wahrung berechtigter Interessen des Vereins (vgl. Artikel 6 Abs. 1 lit. f) DSGVO). Das berechtigte Interesse des Vereins besteht in der Information der &Ouml;ffentlichkeit durch Berichtserstattung &uuml;ber die Aktivit&auml;ten des Vereins. In diesem Rahmen werden personenbezogene Daten einschlie&szlig;lich von Bildern der Teilnehmer zum Beispiel im Rahmen der Berichterstattung &uuml;ber sportliche Ereignisse des Vereins ver&ouml;ffentlicht.</span><span >&nbsp;</span></li>
                                                    </ul>
                                                    <p><span ><strong>4. Die Empf&auml;nger oder Kategorien von Empf&auml;ngern der personenbezogenen Daten:</strong></span></p>
                                                    <ul>
                                                        <li><span >Personenbezogene Daten der Mitglieder, die am Spiel- und Wettkampfbetrieb der Landesfachverb&auml;nde teilnehmen, werden zum Erwerb einer Lizenz, einer Wertungskarte, eines Spielerpasses oder sonstiger Teilnahmeberechtigung an den jeweiligen Landesfachverband weitergegeben.</span></li>
                                                        <li><span >Die Daten der Bankverbindung der Mitglieder werden zum Zwecke des Beitragseinzugs an das Bankinstitut Deutsche Apotheker- und &Auml;rztebank weitergeleitet.<br /></span></li>
                                                    </ul>
                                                    <p><span ><strong>5. Die Dauer, f&uuml;r die die personenbezogenen Daten gespeichert werden oder, falls dies nicht m&ouml;glich ist, die Kriterien f&uuml;r die Festlegung der Dauer:</strong></span></p>
                                                    <ul>
                                                        <li><span >Die personenbezogenen Daten werden f&uuml;r die Dauer der Mitgliedschaft gespeichert. Mit Beendigung der Mitgliedschaft werden die Datenkategorien gem&auml;&szlig; den gesetzlichen Aufbewahrungsfristen weitere zehn Jahre vorgehalten und dann gel&ouml;scht. In der Zeit zwischen Beendigung der Mitgliedschaft und der L&ouml;schung wird die Verarbeitung dieser Daten eingeschr&auml;nkt.<br /></span></li>
                                                    </ul>
                                                    <p><span ><strong>6. Bestimmte Datenkategorien werden zum Zweck der Vereinschronik im Vereinsarchiv gespeichert.</strong> </span></p>
                                                    <p ><span >Hierbei handelt es sich um die Kategorien Vorname, Nachname, Zugeh&ouml;rigkeit zu einer Mannschaft, besondere sportliche Erfolge oder Ereignisse, an denen die betroffene Person mitgewirkt hat. Der Speicherung liegt ein berechtigtes Interesse des Vereins an der zeitgeschichtlichen Dokumentation von sportlichen Ereignissen und Erfolgen und der jeweiligen Zusammensetzung der Mannschaften zugrunde.<br />Alle Daten der &uuml;brigen Kategorien (z.B. Bankdaten, Anschrift, Kontaktdaten) werden mit Beendigung der Mitgliedschaft gel&ouml;scht.</span></p>
                                                    <p><strong>7. Der betroffenen Person stehen unter den in den Artikeln jeweils genannten Voraussetzungen die nachfolgenden Rechte zu:</strong></p>
                                                    <ul>
                                                        <li>das Recht auf Auskunft nach Artikel 15 DSGVO,</li>
                                                        <li>das Recht auf Berichtigung nach Artikel 16 DSGVO,</li>
                                                        <li>das Recht auf L&ouml;schung nach Artikel 17 DSGVO,</li>
                                                        <li>das Recht auf Einschr&auml;nkung der Verarbeitung nach Artikel 18 DSGVO,</li>
                                                        <li>das Recht auf Daten&uuml;bertragbarkeit nach Artikel 20 DSGVO,</li>
                                                        <li>das Widerspruchsrecht nach Artikel 21 DSGVO,</li>
                                                        <li>das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde nach Artikel 77 DSGVO,</li>
                                                        <li>das Recht, eine erteilte Einwilligung jederzeit widerrufen zu k&ouml;nnen, ohne dass die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung hierdurch ber&uuml;hrt wird.</li>
                                                    </ul>
                                                    <p><strong>8. Die Quelle, aus der die personenbezogenen Daten stammen:</strong></p>
                                                    <p>Die personenbezogenen Daten werden grunds&auml;tzlich im Rahmen des Erwerbs der Mitgliedschaft erhoben.</p>
                                                    <p><br /><strong>Ende der Informationspflicht</strong></p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
            <Footer />
        </>
    )
}

export default ImprintContainer

