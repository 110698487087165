import { Avatar, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react'

function SimpleListItem(props) {
    const { icon, object, value, valueIcon, divider, disablePadding, avatarWidth, mrAvatar } = props;
    return (
        <ListItem divider={divider} disableGutters disablePadding={disablePadding}>
            <ListItemAvatar sx={{ minWidth: avatarWidth || '56px', mr: mrAvatar || 'initial' }} variant="circle"><Avatar size="small" sx={{ backgroundColor: "#E3F2FD" }}>{icon}</Avatar></ListItemAvatar>
            <ListItemText sx={{ pl: 0 }} primaryTypographyProps={{ typography: { xs: 'body2', md: 'body1' } }}>
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                    <Typography>{object}</Typography>
                    {valueIcon ?
                        valueIcon
                        :
                        <Typography> {value}</Typography>
                    }
                </Stack>
            </ListItemText>
        </ListItem >
    );
}

export default SimpleListItem