import { Card, CardContent, Divider, Typography, Stack, CardMedia } from '@mui/material'
import React from 'react'
import FileDownloader from './FileDownloader'
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import PdfFirstPageImage from './PdfViewer';
function DownloadCard({image, description, fileurl, filename}) {

    const displayedImage = (image, fileurl) => {
        if (image) {
            return 'image';
        } else if (fileurl.endsWith('pdf')) {
            return 'pdf';
        } else {
            return 'default';
        }
    }

    const activeImage = displayedImage(image, fileurl);

    return (
      <>
        <Card variant="outlined" sx={{ maxWidth: 400, margin: "auto", borderWidth: "2px"}}>
          <CardContent>
            <div style={{ height: "300px", width: "auto", margin: "auto"}}>
                {activeImage === 'image' && <CardMedia image={"/ball_icon.png"} sx={{height: "250px", width: "250px", margin: "auto"}}></CardMedia>}
                {activeImage === 'pdf' && <PdfFirstPageImage pdfUrl={fileurl} />}
                {activeImage === 'default' && 
                    <Stack direction="row" alignItems="center" gap={3} margin="10px">
                        <ImageSearchIcon  />
                        <Typography variant="body2" color="textSecondary">Vorschau nicht vorhanden</Typography>
                    </Stack>
                }

            </div>
            <Divider></Divider>
            <Typography sx={{ minHeight: "80px"}} variant="body2" color="textSecondary" margin="10px">
              {description}
            </Typography>
            <Divider></Divider>
            <div>
            <FileDownloader
                url={fileurl}
                fileName={filename}/>
            </div>
          </CardContent>
        </Card>
      </>
    )
}

export default DownloadCard