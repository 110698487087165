import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function InternalLink(props) {
    const {route,linkName,external} = props;

    const navigate = useNavigate();
    const handleClick = (route) => {
      if(!external)
      {
        navigate(route)
      }
      else
      {
        window.open(route,'_blank').focus();
      }

    }
  return (
    <Button variant="text" sx={{textDecoration: "underline", textTransform: "none"}} onClick={()=>{handleClick(route);}}>{linkName}</Button>
  )
}

export default InternalLink