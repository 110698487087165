import { Alert, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getPosts_api } from '../../api/post';
import Post from '../../components/Post';
import Header from '../../components/Header';
import { Container } from '@mui/system';
import Footer from '../../components/Footer';


function PostContainer(props) {
    const [posts, setPosts] = useState();
    const [loading, setLoading] = useState(false);

    const getPosts = () => {
        setLoading(true);
        var promise
        promise = getPosts_api();
        promise.then((result) => { setPosts(result.data); setLoading(false); })
            .catch((error) => { setLoading(false); });
    }

    useEffect(() => {
        getPosts();
        return () => {
        };
    }, []);

    return (
        <>
            <Header headline="News"></Header>
            <Container maxWidth={"md"} sx={{mt: 3}}>
                <Grid container justifyContent={"center"}>
                    <Grid item container xs={12} md={10} spacing={2} justifyContent={"center"}>
                        {loading == true ?
                            // show loading icon as long as posts are populated
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <CircularProgress sx={{ mt: 10 }}></CircularProgress>
                                </Grid>
                            </Grid>
                            :
                            <>
                                {/* loop over post array */}
                                {posts && posts.length > 0 ? posts.map((post, i) =>
                                    <Grid item xs={12} key={i}>
                                        <Post src={post.img_url} title={post.title} content={post.content} author={post.author_name} commentsCount={post.comments_count} post={post}></Post>
                                    </Grid>
                                )
                                    :
                                    <Grid item xs={12}>
                                        <Alert severity="info"><Typography>Keine Beiträge vorhanden</Typography></Alert>
                                    </Grid>
                                }
                            </>
                        }
                    </Grid>
                </Grid>
            </Container>
            <Footer/>
        </>
    )
}

export default PostContainer