import { LockSharp, TaskAltSharp } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { saveAs } from 'file-saver';
import React from 'react'

function SubscritpionCard(props) {
    const { data } = props;

    const handleDownload = () => {
        fetch("/Aufnahme.pdf")
          .then(response => response.blob())
          .then(blob => saveAs(blob,"Aufnahmeantrag_HTC_Braunschweig.pdf" ))
          .catch(error => console.error('Error downloading file:', error));
      };

    return (
        <Card>
            <CardContent sx={{ backgroundColor: "#e9e9e9" }}>
                <Typography variant="h5" align="center" color="text.secondary">{data.title ? data.title : "Einzeltarif"}</Typography>
            </CardContent>

            <CardContent sx={{}}>
                <Grid container direction="row" justifyContent="center" spacing={3}>
                    {data.categories.map((category, i) => (
                        <Grid item xs={12} key={i}>
                            <Stack direction="row" alignItems={"center"} justifyContent="space-between" spacing={3} mx={2}>
                                <Stack>
                                    <Typography variant="body1" >{category.name}</Typography>
                                    <Typography variant="body2" color="text.secondary">{category.secondaryName}</Typography>
                                </Stack>
                                <Typography variant="h4" color="text.secondary">{category.price}</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>

            <CardContent>
                <Grid container direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                    <List dense>
                            {data.featuresEnabled.map((feature, i) => (
                                <ListItem key={i}>
                                    <ListItemIcon><TaskAltSharp color='success'></TaskAltSharp></ListItemIcon>
                                    <ListItemText sx={{ color: "text.secondary" }}>{feature.name}</ListItemText>
                                </ListItem>
                            ))}
                            <ListSubheader></ListSubheader>
                            {data.featuresDisabled.map((feature, i) => (
                                <ListItem key={i}>
                                    <ListItemIcon><LockSharp color='warning'></LockSharp></ListItemIcon>
                                    <ListItemText sx={{ color: "text.secondary" }}>{feature.name}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" color={"text.secondary"}>Die Jahresmitgliedschaft im Verein ermöglicht die Nutzung der Tennisanlage. Nicht enthalten sind Training und Hallenplätze.</Typography>
                    </Grid>
                </Grid>

            </CardContent>

            <CardContent sx={{ backgroundColor: "#e9e9e9" }}>
                <Grid container justifyContent={"center"}>
                    <Button variant={"contained"} onClick={()=>handleDownload()} sx={{textTransform: "none"}}>Download Antragsformular</Button>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SubscritpionCard