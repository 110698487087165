import { Divider, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ROOT, TEAMS, CONTACTS, SUPPLIES, PRICING, NEWS, DIRECTION, EVENTS } from '../navigation/CONSTANTS';

function DrawerMenu(props) {
    const { open, setOpen } = props;

    const navigate = useNavigate();

    const handleClick = (route, external = false) => {
        if (!external) {
            navigate(route)
        }
        else {
            window.open(route, '_blank').focus();
        }
    }

    return (
        <Drawer anchor={"right"} open={open} onClose={() => { setOpen(false); }}>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(ROOT); }}>
                        <ListItemText>Home</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(NEWS); }}>
                        <ListItemText>News</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(DIRECTION); }}>
                        <ListItemText>Anfahrt</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(PRICING); }}>
                        <ListItemText>Mitgliedschaft</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(TEAMS); }}>
                        <ListItemText>Mannschaften</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(CONTACTS); }}>
                        <ListItemText>Ansprechpartner</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(EVENTS); }}>
                        <ListItemText>Termine</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick(SUPPLIES); }}>
                        <ListItemText>Nützliches</ListItemText>
                    </ListItemButton>
                </ListItem>
                <Divider>Links</Divider>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick("https://portal.htc-braunschweig.de", true); }}>
                        <ListItemText>HTC Portal</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={() => { handleClick("https://training.htc-braunschweig.de", true); }}>
                        <ListItemText>Trainingsplaner</ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    )
}

export default DrawerMenu