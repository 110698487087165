import React from 'react'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { EmojiEvents, EventNote } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

function EventTimelineItem({ event,hideConnector, ...props }) {
    return (
        <TimelineItem sx={{
            [`& .${timelineOppositeContentClasses.root}`]: { flex: { xs: 0.4, lg: 1, }, },
        }}>
            <TimelineOppositeContent textAlign="center" variant={'body1'} color={"text.secondary"} sx={{ py: '18px', pr: 1,pl:0 }} >{event && event.start && format(new Date(event.start), "dd. MMM yy", { locale: de })}</TimelineOppositeContent>
            <TimelineSeparator >
                <TimelineDot color={event.type == "Turnier" ? "primary" : "secondary"} variant="outlined">
                    {event.type == "Turnier" ?
                        <EmojiEvents color="primary" />
                        :
                        <EventNote color="secondary" />
                    }</TimelineDot>
                {hideConnector ? <></> : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent component={'span'} sx={{ py: '12px', px: 2 }}>
                <Stack >
                    <Typography variant={'body2'} color="text.secondary" sx={{ textTransform: "uppercase" }}>{event.type}</Typography>
                    <Typography variant={'body1'} >{event.name}</Typography>
                </Stack>
            </TimelineContent>
        </TimelineItem>
    )
}

export default EventTimelineItem