import React from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Card, CardContent } from '@mui/material'

function DirectionContainer() {
  return (
    <>
      <Header headline="Anfahrt"></Header>
      <Card sx={{width: "80%", margin: "auto", marginTop: "2rem"}}>
        <CardContent sx={{margin: "auto"}}>
            <div style={{width: "100%"}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2443.628382593119!2d10.548258269566565!3d52.23196907043453!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aff58a63fe34e1%3A0x99941ecce87cff9c!2sHeidberger%20Tennis-Club%20e.V.!5e0!3m2!1sde!2sde!4v1697965575993!5m2!1sde!2sde"
                    loading="lazy"
                    width="100%"
                    height="400px"
                    referrerPolicy="no-referrer-when-downgrade"
                    style={{border:0}} 
                    allowFullScreen={true}>
                </iframe>
            </div>
        </CardContent>
      </Card>
      
      <Footer/>
    </>
  )
}

export default DirectionContainer