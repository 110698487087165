import { Menu } from '@mui/icons-material'
import { AppBar, Box, Button, CardMedia, Container, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import DrawerMenu from './DrawerMenu';
import title from '../assets/titlepic.jpg'
import logo from '../assets/logo.png'
import { CONTACTS, NEWS, PRICING, ROOT, SUPPLIES, TEAMS, DIRECTION, EVENTS } from '../navigation/CONSTANTS';


function Header({headline,...props}) {

    const [open, setOpen] = useState();
    const location = useLocation();

    const navigate = useNavigate();

    const handleClick = (route, external = false) => {
        if (!external) {
            navigate(route)
        }
        else {
            window.open(route, '_blank').focus();
        }
    }

    return (
        <>
            {/* <Box sx={{ backgroundColor: "#d9d9d9" }}>
                <Container maxWidth="md" sx={{py: { xs: 3, md: 5 } }}>
                    <Stack sx={{ backgroundColor: "transparent" }}>
                        <Typography sx={{ color: "text.secondary", textAlign: "start", typography: { xs: "body1", sm: "h6", md: "h5" } }}> Willkommen beim</Typography>
                        <Typography sx={{ color: "text.primary", textAlign: "center", typography: { xs: "h5", sm: "h4", md: "h2" } }}> Heidberger Tennis Club</Typography>
                        <Typography sx={{ color: "text.secondary", textAlign: "end", typography: { xs: "body1", sm: "h6", md: "h5" } }}> Tennis mit Tradition seit 1980 </Typography>
                    </Stack>
                </Container>
            </Box> */}
            <Box sx={{ position: "relative" }}>
                {/* <CardMedia image={"https://img.freepik.com/free-vector/white-gray-geometric-pattern-background-vector_53876-136510.jpg?w=1380&t=st=1704017781~exp=1704018381~hmac=bb7421e55d4eee487b0777c3449507420b95b31704ee045934a914a7cd8b746f"} sx={{ height: { xs: "100px", sm: "150px",md: "200px", xl: "250px" }}}></CardMedia> */}
                <CardMedia image={"https://img.freepik.com/free-vector/realistic-white-golden-geometric-background_79603-2032.jpg?w=1380&t=st=1704018058~exp=1704018658~hmac=975ec4feaf81aa14c78dc730476d556c294031706d59e78abd1f9fb05a0eaddd"} sx={{ height: { xs: "150px", sm: "150px",md: "200px", xl: "250px" }}}></CardMedia>
                {/* <CardMedia image={"https://img.freepik.com/free-vector/abstract-blue-geometric-shapes-background_1035-17545.jpg?w=1380&t=st=1704017392~exp=1704017992~hmac=54c730fd2010861b37e0c5d4c2c0aacedd0828f2b9cbebeea5189ed8ea30ed28"} sx={{ height: { xs: "100px", sm: "150px",md: "200px", xl: "250px" }}}></CardMedia> */}

                <Box sx={{ width: "90%",position: "absolute", color: "white", bottom: "50%", left: "50%", transform: "translate(-50%, 50%)" }}>
                <Container maxWidth="md">
                    <Stack sx={{ backgroundColor: "transparent" }}>
                        <Typography sx={{ color: "text.secondary", textAlign: "start", typography: { xs: "body1", sm: "h6", md: "h5" } }}> Willkommen beim</Typography>
                        <Typography sx={{ color: "text.primary", textAlign: "center", typography: { xs: "h5", sm: "h4", md: "h2" } }}> Heidberger Tennis Club</Typography>
                        <Typography sx={{ color: "text.secondary", textAlign: "end", typography: { xs: "body1", sm: "h6", md: "h5" } }}> Tennis mit Tradition seit 1980 </Typography>
                    </Stack>
                </Container>
                </Box>
            </Box>
            <AppBar position="sticky" >
                <Toolbar variant="dense" sx={{ justifyContent: { xs: "space-between", md: "center" }, backgroundColor: "white" }} >
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <Stack direction="row" spacing={1}>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary",textDecoration: location.pathname == ROOT && "underline",textDecorationStyle: "double"  }} onClick={() => { handleClick(ROOT) }}>Home</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary",textDecoration: location.pathname == NEWS && "underline",textDecorationStyle: "double"  }} onClick={() => { handleClick(NEWS) }}>News</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary", textDecoration: location.pathname == DIRECTION && "underline",textDecorationStyle: "double"   }} onClick={() => { handleClick(DIRECTION) }}>Anfahrt</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary", textDecoration: location.pathname == PRICING && "underline",textDecorationStyle: "double"  }} onClick={() => { handleClick(PRICING) }}>Mitgliedschaft</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary", textDecoration: location.pathname == CONTACTS && "underline",textDecorationStyle: "double"   }} onClick={() => { handleClick(CONTACTS) }}>Kontakt</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary", textDecoration: location.pathname == TEAMS && "underline",textDecorationStyle: "double"   }} onClick={() => { handleClick(TEAMS) }}>Mannschaften</Button>
                            {/* <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }}>Anlage</Button> */}
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary", textDecoration: location.pathname == EVENTS && "underline",textDecorationStyle: "double"   }} onClick={() => { handleClick(EVENTS) }}>Termine</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary", textDecoration: location.pathname == SUPPLIES && "underline",textDecorationStyle: "double"   }} onClick={() => { handleClick(SUPPLIES) }}>Nützliches</Button>

                            <Divider orientation='vertical' flexItem></Divider>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick("https://portal.htc-braunschweig.de", true); }}>Portal</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick("https://training.htc-braunschweig.de", true); }}>Trainingsplaner</Button>
                        </Stack>
                    </Box>
                    {/* fake icon to achive centering */}
                    <Box sx={{ display: { xs: "block", md: "none" }}}>
                        <IconButton><Menu sx={{color: "#fff"}}></Menu></IconButton>
                    </Box>
                    <Box sx={{ display: { xs: "block", md: "none" }}}>
                        <Typography variant="h6" color={"text.secondary"}>{headline}</Typography>
                    </Box>
                    <Box sx={{ display: { xs: "block", md: "none" }, color: "#000" }}>
                        <IconButton onClick={() => setOpen(!open)}><Menu></Menu></IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            <DrawerMenu open={open} setOpen={setOpen}></DrawerMenu>
        </>
    )
}

export default Header