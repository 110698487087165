import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ContactsContainer from '../pages/contacts/ContactsContainer';
import HomeContainer from '../pages/home/HomeContainer';
import PricingContainer from '../pages/pricing/PricingContainer';
import TeamContainer from '../pages/teams/TeamContainer';
import SuppliesContainer from '../pages/supplies/SuppliesContainer';
import DirectionContainer from '../pages/directions/DirectionContainer';
import { ROOT, TEAMS, CONTACTS, SUPPLIES, PRICING, NEWS, DIRECTION, IMPRINT, EVENTS } from './CONSTANTS';
import NotFound from './NotFound';
import PostContainer from '../pages/news/PostContainer';
import ImprintContainer from '../pages/imprint/ImprintContainer';
import EventsContainer from '../pages/events/EventsContainer';

function RouterConfig() {

    const router = createBrowserRouter([
        {
            path: ROOT,
            element: <HomeContainer></HomeContainer>,
            errorElement: <NotFound/>
        },
        {
            path: TEAMS,
            element: <TeamContainer/>
        },
        {
            path: CONTACTS,
            element: <ContactsContainer/>
        },
        {
            path: SUPPLIES,
            element: <SuppliesContainer/>
        },
        {
            path: PRICING,
            element: <PricingContainer/>
        },
        {
            path: NEWS,
            element: <PostContainer/>
        },
        {
            path: DIRECTION,
            element: <DirectionContainer/>
        },
        {
            path: IMPRINT,
            element: <ImprintContainer/>
        },
        {
            path: EVENTS,
            element: <EventsContainer/>
        }


    ]);

    return (
        <RouterProvider router={router}>
        </RouterProvider>
  )
}

export default RouterConfig