import { Typography, Divider, Grid } from '@mui/material'
import React from 'react'
import Header from '../../components/Header'
import DownloadCard from '../../components/DownloadCard'
import './DownloadCards.css'
import Footer from '../../components/Footer'

function SuppliesContainer() {
  return (
    <>
      <Header headline="Nützliches"></Header>

      <Grid container direction={"column"} spacing={3}>
        <Grid item xs={10} md={5} lg={4}>
          <Typography color="text.secondary" sx={{ textAlign: "center", pt: "20px", pb: "20px", typography: { xs: "body1", md: "h6" } }}>
            Hier stehen für Sie nützliche Dokumente zum Download bereit
          </Typography>
        </Grid>
      </Grid>

      <div className="download-cards-container">
      <div className="download-card">
          <DownloadCard fileurl="/Anfahrt.pdf" filename="Anfahrt_HTC_Braunschweig.pdf" description="Hier finden Sie eine Wegbeschreibung für die Anfahrt zu unserer Anlage"/>
        </div>
        <div className="download-card">
          <DownloadCard fileurl="/Aufnahme.pdf" filename="Aufnahmeantrag_HTC_Braunschweig.pdf" description="Hier können Sie den aktuellen Mitgliedsantrag herunterladen.
            Bitte geben Sie den ausgefüllten Antrag bei einer zuständigen Person ab"/>
        </div>
        <div className="download-card">
          <DownloadCard fileurl="/Satzung.pdf" filename="Satzung_HTC_Braunschweig.pdf" description="Hier können Sie die aktuelle Satzung herunterladen"/>
        </div>
        <div className="download-card">
          <DownloadCard fileurl="/Hausordnung_Clubhaus.pdf" filename="Hausordnung_Clubhaus_HTC_Braunschweig.pdf" description="Hier können Sie die aktuelle Hausordnung für die Benutzung unseres Clubhauses herunterladen"/>
        </div>
        <div className="download-card">
          <DownloadCard fileurl="/Gastspielordnung.pdf" filename="Gastspielordnung_HTC_Braunschweig.pdf" description="Hier können Sie die aktuelle Gastspielordnung herunterladen"/>
        </div>
        <div className="download-card">
          <DownloadCard fileurl="/Anlagen-,Platz-&Spielordnung.pdf" filename="Anlagen-,Platz-&Spielordnung_HTC_Braunschweig.pdf" description="Hier können Sie die aktuelle Anlagen-,Platz-&Spielordnung herunterladen"/>
        </div>
        <div className="download-card">
          <DownloadCard fileurl="/Hinweise_Mannschaftsangehörige.pdf" filename="Hinweise_Mannschaftsangehörige_HTC_Braunschweig.pdf" description="Hier finden Sie Hinweise für Mannschaftsangehörige als PDF Datei herunterladen"/>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default SuppliesContainer