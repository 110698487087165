import { Typography, Card, CardContent, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react'
import Header from '../../components/Header'
import TeamCard from '../../components/TeamCard'
import teamsMenSummer from '../../mocks/teams_men_summer.json'
import teamsWomenSummer from '../../mocks/teams_women_summer.json'
import teamsYouthSummer from '../../mocks/teams_youth_summer.json'
import teamsMenWinter from '../../mocks/teams_men_winter.json'
import teamsWomenWinter from '../../mocks/teams_women_winter.json'
import Footer from '../../components/Footer'
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import AcUnitIcon from '@mui/icons-material/AcUnit';

function TeamContainer() {
  const [value, setValue] = React.useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Header headline="Mannschaften"></Header>
      <TabContext value={value}>
        <TabList centered sx={{ maxWidth: "90%", margin: "auto", borderWidth: "2px", marginTop: "5px"}} onChange={handleChange} aria-label="icon label tabs example">
          <Tab icon={<WbSunnyIcon />} value="0" label="Sommer" />
          <Tab icon={<AcUnitIcon />} value="1" label="Winter"/>
        </TabList>
        <TabPanel sx={{paddingTop: "0px"}} value="0" index={0}>
          <Card variant="outlined" sx={{maxWidth: "95%", margin: "auto", borderWidth: "2px", marginTop: "-2px"}}>
            <CardContent>
              <Typography sx={{ color: "text.primary", textAlign: "start", typography: { xs: "h4", md: "h4" }, margin: "10px" }}> Herren </Typography>
              {teamsMenSummer.map((team) => (
              <div >
                <TeamCard {...team} />
              </div>
              ))}
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{maxWidth: "95%", margin: "auto", borderWidth: "2px", marginTop: "20px"}}>
            <CardContent>
              <Typography sx={{ color: "text.primary", textAlign: "start", typography: { xs: "h4", md: "h4" }, margin: "10px" }}> Damen </Typography>
              {teamsWomenSummer.map((team) => (
              <div >
                <TeamCard {...team} />
              </div>
              ))}
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ maxWidth: "95%", margin: "auto", borderWidth: "2px", marginTop: "20px"}}>
            <CardContent>
              <Typography sx={{ color: "text.primary", textAlign: "start", typography: { xs: "h4", md: "h4" }, margin: "10px" }}> Junioren </Typography>
              {teamsYouthSummer.map((team) => (
              <div >
                <TeamCard {...team} />
              </div>
              ))}
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel sx={{paddingTop: "0px"}} value="1" index={1}>
          <Card variant="outlined" sx={{maxWidth: "95%", margin: "auto", borderWidth: "2px", marginTop: "-2px"}}>
            <CardContent>
              <Typography sx={{ color: "text.primary", textAlign: "start", typography: { xs: "h4", md: "h4" }, margin: "10px" }}> Herren </Typography>
              {teamsMenWinter.map((team) => (
              <div >
                <TeamCard {...team} />
              </div>
              ))}
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{maxWidth: "95%", margin: "auto", borderWidth: "2px", marginTop: "20px"}}>
            <CardContent>
              <Typography sx={{ color: "text.primary", textAlign: "start", typography: { xs: "h4", md: "h4" }, margin: "10px" }}> Damen </Typography>
              {teamsWomenWinter.map((team) => (
              <div >
                <TeamCard {...team} />
              </div>
              ))}
            </CardContent>
          </Card>
        </TabPanel>
      </TabContext>
      <Footer/>
    </>
  )
}

export default TeamContainer